<template>
  <div
    class="modal"
    style="z-index: 1006 !important;"
    v-bind:class="{ 'is-active': showChangingRegionModal }"
  >
  <div class="modal-background"></div>
  <div class="modal-card">
    <section class="modal-card-body">
      Loading ...
    </section>
  </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    showChangingRegionModal: {
      type: Boolean
    }
  },
}
</script>
