var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      class: { "is-active": _vm.showChangingRegionModal },
      staticStyle: { "z-index": "1006 !important" },
    },
    [_c("div", { staticClass: "modal-background" }), _vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-card" }, [
      _c("section", { staticClass: "modal-card-body" }, [
        _vm._v(" Loading ... "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }